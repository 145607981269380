.itinerary-container {
  max-width: 100%;
  margin: 0 auto;
  padding: var(--section-padding);
  background-color: var(--white-2);
  border-radius: var(--radius-6);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.itinerary-container h2 {
  font-size: var(--fs-2);
  font-weight: var(--fw-700);
  color: var(--jet);
  margin-bottom: 20px;
}

.day-container {
  margin-bottom: 30px;
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 20px;
}

.day-container h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-600);
  color: var(--jet);
  margin-bottom: 10px;
}

.day-container p {
  font-size: var(--fs-6);
  line-height: 1.6;
  color: var(--battleship-gray);
}

.day-container strong {
  font-weight: var(--fw-700);
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
  margin-bottom: 5px;
}

ul li:before {
  content: "•";
  color: var(--viridian-green);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.inclusions h3,
.exclusions h3 {
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
  color: var(--jet);
  margin-bottom: 15px;
}

.inclusions ul,
.exclusions ul {
  padding-left: 20px;
}

.inclusions li,
.exclusions li {
  font-size: var(--fs-6);
  line-height: 1.7;
  color: var(--battleship-gray);
}

@media (max-width: 768px) {
  .itinerary-container {
    padding: 30px;
  }

  .itinerary-container h2 {
    font-size: var(--fs-3);
    margin-bottom: 15px;
  }

  .day-container h3 {
    font-size: var(--fs-2);
    margin-bottom: 8px;
  }

  .day-container p {
    font-size: var(--fs-5);
  }

  .inclusions h3,
  .exclusions h3 {
    font-size: var(--fs-3);
    margin-bottom: 12px;
  }

  .inclusions li,
  .exclusions li {
    font-size: var(--fs-5);
  }
}
