.section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-lg-12 {
    display: flex;
    justify-content: center;
  }
  
  .mahesh {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .section-title{
    padding:3%;
    font-size: 36px;
  }