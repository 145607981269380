.activities-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .activity-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .activity-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .activity-info {
    padding: 16px;
  }
  
  .activity-name {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .activity-description {
    font-size: 1rem;
    color: #555;
  }
  